import React from 'react';
import Button from '../components/Button';
import { Column, Container, Row } from '../components/Grid';
import Icon from '../components/Icon';
import Image from '../components/Image';
import Layout from '../components/Layout';
import OutboundLink from '../components/OutboundLink';
import Section from '../components/Section';
import Subfooter from '../components/Subfooter';
import Video from '../components/Video';
import Resource from '../components/Resource';
import { Link } from 'gatsby';
import bgImage from '../images/CPdetail-image-videostill-bkgd-Kim.jpg';
import '../styles/styles.scss';

const MSCareerProfile = () => (
  <Layout title="Health Equity Careers | Manufacturing Scientist">
    <Section className="about-mission">
      <Container>
        <Button
          link
          to="/career-profiles"
          className="individual-profile__return"
        >
          &#60; View All
        </Button>
        <h1 className="h1 text-bold">Health Equity Careers</h1>
      </Container>
    </Section>
    <Section className="pt-0">
      <Container fullWidth>
        <Row>
          <Column>
            <div
              className="career-profile__video-wrapper"
              style={{ backgroundImage: `url('${bgImage}')` }}
            >
              <div className="career-profile__link-container">
                <Link className="career-profile__link" to="/operations-manager">
                  <Icon name="arrowleft" />
                </Link>
              </div>
              <div className="career-profile__video-container">
                <Video guidSrc="c37a6960-1d97-4440-bd6f-70e3e03af695" />
              </div>
              <div className="career-profile__link-container">
                <Link
                  className="career-profile__link"
                  to="/pharmaceutical-sciences"
                >
                  <Icon name="arrowright" />
                </Link>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    <Section className="pt-0 pb-0">
      <Container>
        <Row>
          <Column size={8}>
            <h2 className="h2 mt-1">Manufacturing Scientist</h2>
            <p>
              Learn more about this career by exploring the in-depth career fact
              sheet and engage students with hands-on activities for any
              environment.
            </p>
            {/* Quick links */}
            <div className="individual-profile__links">
              Quick Links:&nbsp;
              <Button link to="#about">
                About
              </Button>
              |
              <Button link to="#evaluate">
                Evaluate Your Interest
              </Button>
              |
              <Button link to="#activate">
                Activate Your Interest
              </Button>
            </div>

            <div>
              <Resource
                title="About"
                description="Manufacturing Scientists are dedicated to improving the process of manufacturing and supporting teammates with innovative, high-quality products. Whether Kimberly is identifying unknown particles in the lab or collaborating with international colleagues, she is guided by her dedication to providing better healthcare and lifesaving medications to patients."
                actions={[
                  {
                    label: 'Career Profile',
                    type: 'dropdown',
                    actions: [
                      {
                        label: 'English',
                        path:
                          '/pdfs/career-profiles/Takeda-CareerProfile-ManufacturingScientist.pdf',
                        type: 'download',
                        title: 'Manufacturing Scientist Career Profile-English',
                      },
                      {
                        label: 'German',
                        path:
                          '/pdfs/career-profiles/Takeda-CareerProfile-ManufacturingScientist_DE.pdf',
                        type: 'download',
                        title: 'Manufacturing Scientist Career Profile-German',
                      },
                      {
                        label: 'Spanish',
                        path:
                          '/pdfs/career-profiles/Takeda-CareerProfile-ManufacturingScientist_ES.pdf',
                        type: 'download',
                        title: 'Manufacturing Scientist Career Profile-Spanish',
                      },
                      {
                        label: 'Portuguese',
                        path:
                          '/pdfs/career-profiles/Takeda-CareerProfile-ManufacturingScientist_PR.pdf',
                        type: 'download',
                        title:
                          'Manufacturing Scientist Career Profile-Portuguese',
                      },
                      {
                        label: 'Italian',
                        path:
                          '/pdfs/career-profiles/Takeda-CareerProfile-ManufacturingScientist_IT.pdf',
                        type: 'download',
                        title: 'Manufacturing Scientist Career Profile-Italian',
                      },
                      {
                        label: 'French',
                        path:
                          '/pdfs/career-profiles/Takeda-CareerProfile-ManufacturingScientist_FR.pdf',
                        type: 'download',
                        title: 'Manufacturing Scientist Career Profile-French',
                      },
                      {
                        label: 'Chinese',
                        path:
                          '/pdfs/career-profiles/Takeda-CareerProfile-ManufacturingScientist_Chinese.pdf',
                        type: 'download',
                        title: 'Manufacturing Scientist Career Profile-Chinese',
                      },
                      {
                        label: 'Japanese',
                        path:
                          '/pdfs/career-profiles/Takeda-CareerProfile-ManufacturingScientist_Japanese.pdf',
                        type: 'download',
                        title:
                          'Manufacturing Scientist Career Profile-Japanese',
                      },
                      {
                        label: 'Hindi',
                        path:
                          '/pdfs/career-profiles/Takeda-CareerProfile-ManufacturingScientist_HINDI.pdf',
                        type: 'download',
                        title: 'Manufacturing Scientist Career Profile-Hindi',
                      },
                      {
                        label: 'Russian',
                        path:
                          '/pdfs/career-profiles/Takeda-CareerProfile-ManufacturingScientist_RU.pdf',
                        type: 'download',
                        title: 'Manufacturing Scientist Career Profile-Russian',
                      },
                    ],
                  },
                ]}
              />
            </div>
            <div className="individual-profile__evaluate mt-1">
              <h3 className="h3" id="evaluate">
                Evaluate Your Interest
              </h3>
              <p>
                Find out more about this career and see how it aligns with
                student interests.
              </p>
              <ul>
                <li>
                  Collaboration and communication with teammates are essential
                  to successful manufacturing.
                </li>
                <li>
                  Problem-solving skills are needed to troubleshoot in the lab
                  and beyond.
                </li>
                <li>
                  Close attention-to-detail is crucial for work involving
                  protocols, processes, and reports.
                </li>
                <li>
                  Flexibility is needed when working in a variety of
                  environments—sometimes cold, hot, or wet.
                </li>
              </ul>
            </div>
            <div className="individual-profile__activate">
              <Resource
                title="Activate Your Interests"
                description="Bring this career to life with a classroom activity that
              empowers students to identify health inequities in their own
              communities and promote health equity awareness. This activity
              is designed as a companion to the video profile."
                actions={[
                  {
                    label: 'Student Activation',
                    type: 'dropdown',
                    actions: [
                      {
                        label: 'English',
                        path:
                          '/pdfs/career-profiles/Takeda-BetterHealth-StudentActivation-ImprovingTheWorldAroundUs.pdf',
                        type: 'download',
                        title:
                          'Manufacturing Scientist Student Activation-English',
                      },
                      {
                        label: 'German',
                        path:
                          '/pdfs/career-profiles/Takeda-BetterHealth-StudentActivation-ImprovingTheWorldAroundUs_DE.pdf',
                        type: 'download',
                        title:
                          'Manufacturing Scientist Student Activation-German',
                      },
                      {
                        label: 'Spanish',
                        path:
                          '/pdfs/career-profiles/Takeda-BetterHealth-StudentActivation-ImprovingTheWorldAroundUs_ES.pdf',
                        type: 'download',
                        title:
                          'Manufacturing Scientist Student Activation-Spanish',
                      },
                      {
                        label: 'Portuguese',
                        path:
                          '/pdfs/career-profiles/Takeda-BetterHealth-StudentActivation-ImprovingTheWorldAroundUs_PR.pdf',
                        type: 'download',
                        title:
                          'Manufacturing Scientist Student Activation-Portuguese',
                      },
                      {
                        label: 'Italian',
                        path:
                          '/pdfs/career-profiles/Takeda-BetterHealth-StudentActivation-ImprovingTheWorldAroundUs_IT.pdf',
                        type: 'download',
                        title:
                          'Manufacturing Scientist Student Activation-Italian',
                      },
                      {
                        label: 'French',
                        path:
                          '/pdfs/career-profiles/Takeda-BetterHealth-StudentActivation-ImprovingTheWorldAroundUs_FR.pdf',
                        type: 'download',
                        title:
                          'Manufacturing Scientist Student Activation-French',
                      },
                      {
                        label: 'Chinese',
                        path:
                          '/pdfs/career-profiles/Takeda-BetterHealth-StudentActivation-ImprovingTheWorldAroundUs_Chinese.pdf',
                        type: 'download',
                        title:
                          'Manufacturing Scientist Student Activation-Chinese',
                      },
                      {
                        label: 'Japanese',
                        path:
                          '/pdfs/career-profiles/Takeda-BetterHealth-StudentActivation-ImprovingTheWorldAroundUs_Japanese.pdf',
                        type: 'download',
                        title:
                          'Manufacturing Scientist Student Activation-Japanese',
                      },
                      {
                        label: 'Hindi',
                        path:
                          '/pdfs/career-profiles/Takeda-BetterHealth-StudentActivation-ImprovingTheWorldAroundUs_HINDI.pdf',
                        type: 'download',
                        title:
                          'Manufacturing Scientist Student Activation-Hindi',
                      },
                      {
                        label: 'Russian',
                        path:
                          '/pdfs/career-profiles/Takeda-BetterHealth-StudentActivation-ImprovingTheWorldAroundUs_RU.pdf',
                        type: 'download',
                        title:
                          'Manufacturing Scientist Student Activation-Russian',
                      },
                    ],
                  },
                ]}
              />
            </div>
          </Column>
          {/* Side column */}
          <Column size={4} className="pl-3 pt-4 pr-3">
            <Image
              filename="global-image-headshot-Kim@2x.png"
              className="careerProfiles__headshot"
            />
            <div className="individual-profile__holder">
              <div className="individual-profile__quote">
                “In my heart, I know that everything I do is having a direct
                effect on someone out there that needs this medication to live.”
                <div className="mt-1">Kimberly Aguilar, BS</div>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    <Section className="pt-0">
      <Container>
        <Row style={{ justifyContent: 'center' }}>
          <Column size={8}>
            <Image filename="global-languages-promo-box@2x.png" />
          </Column>
        </Row>
        <Row className="mt-2">
          <Column size={12}>
            <h3 className="h3">Additional Careers to Explore</h3>
            <p>
              Discover the many exciting STEM careers that help support health
              equity globally. Learn what inspires these diverse individuals by
              exploring the below Career Profiles.
            </p>
          </Column>
        </Row>
        <Row className="mt-2">
          <Column size={6} className="pr-1">
            <OutboundLink to="/patient-affairs">
              <Image filename="CPdetail-image-careers-vp.jpg" />
            </OutboundLink>
            <h3 className="h3">
              Vice President and Head of the Center
              <br />
              for Health Equity and Patient Affairs
            </h3>
            <p>
              Find out what is needed to oversee a Center of Excellence focused
              on addressing health inequities from a doctor who is driven by a
              lifelong passion to provide care for others.
            </p>
            <Button to="/patient-affairs" className="button--link">
              Learn more <Icon name="arrowright" marginLeft />
            </Button>
          </Column>
          <Column size={6} className="pl-1">
            <OutboundLink to="/pharmaceutical-sciences">
              <Image filename="CPdetail-image-careers-AssocDir.jpg" />
            </OutboundLink>
            <h3 className="h3">
              Associate Director of Pharmaceutical Sciences
            </h3>
            <p>
              Learn how advanced technologies like robotics and artifical
              intelligence are being incorporated into the process of providing
              better healthcare to patients.
            </p>
            <Button to="/pharmaceutical-sciences" className="button--link">
              Learn more <Icon name="arrowright" marginLeft />
            </Button>
          </Column>
        </Row>
      </Container>
    </Section>

    <Subfooter
      className="subfooter__quote-careers-no-quotes"
      textRight
      textRed
      footerImage="CPdetail-kim-image-QuoteBox.jpg"
      footerText="Delivering high-quality, innovative medicines and products, to patients when and where they need them."
    />
  </Layout>
);

export default MSCareerProfile;
